@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.iconRojghar {
  padding: 15px;
  height: 100px;
  width: 100px;
} */





























.user_cardL {
  height: 400px;
  width: 400px;
  margin-top: auto;
  margin-bottom: auto;
  /* background: #fff; */
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%);
  /* border-radius: 5px; */
  background-color: rgb(255 255 255 /20%);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
/* #85848c */
.login_name_wrapperL {
  height: 20% !important;
  min-height: 200px;
  margin-bottom: auto;
  margin-top: auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #FFF;
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;
}

.login_logo_containerL {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background:  #85848c;
  padding: 10px;
  text-align: center;
}

.login_logoL {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form_containerL {
  margin-top: 100px;
}

.login_btnL {
  width: 100%;

  color: white !important;
}

.login_btnL:hover {
  background: #2c44b0 !important;
}

.login_containerL {
  padding: 0 2rem;
}

.input-group-text {
  background: #233588 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_userL,
.input_passL:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.custom-checkboxL .custom-control-inputL:checked~.custom-control-labelL::before {
  background-color: #233588 !important;
}

.input-group-textL {
  height: 38px;
}

.Rg-bg {
  height: 100%;
  background-image: url(/static/media/rojgharbg.20e7afcf.jpg);
  
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* height: 95vh; */
  /* background-color: #2b4162;
  background-image: linear-gradient(315deg, #2b4162 0%, #12100e 74%) */
}

.card-glass {
  border-radius: 5px;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 0%);
  /* border-radius: 5px; */
  background-color:rgb(83 79 79 / 53%);
  opacity: 1;

  -webkit-backdrop-filter: blur(20px);

          backdrop-filter: blur(20px);

}

/* .find-job-card-text {
  color:black !important;
} */
.find-job-card-textD {
  color: white !important;
}

.MuiToolbar-gutters{
  position: -webkit-sticky!important;
  position: sticky!important;
  top: 0 !important;
  background: white;
}
.card-rojghar {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
    border-radius: 4% !important;
}

.rojghar-text {
    color: rgb(172, 24, 24);
}

.card-rojghar>img {
    border-radius: 5% !important;
}

.Login-card {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
    border-radius: 5% !important;
}

.Header-ekta {
    color: rgb(172, 24, 24);
}



/* 121417 */






.cardP {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
}

.cardP {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-bodyP {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-smP {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-smP>.colP,
.gutters-smP>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}

.mb-3P,
.my-3P {
    margin-bottom: 1rem !important;
}

.bg-gray-300P {
    background-color: #e2e8f0;
}

.h-100P {
    height: 100% !important;
}

.shadow-noneP {
    box-shadow: none !important;
}
.page {
    background-color: #f8f5de;
    background-image: linear-gradient(to right, rgba(255,210,0,0.4), rgba(200, 160, 0, 0.1) 11%, rgba(0,0,0,0) 35%, rgba(200, 160, 0, 0.1) 65%)!important;
    box-shadow: inset 0 0 75px rgba(255,210,0,0.3), inset 0 0 20px rgba(255,210,0,0.4), inset 0 0 30px rgba(220,120,0,0.8)!important;
    color: rgba(0,0,0,0.5);
    font-family: "AustralisProSwash-Italic";
    /* width: calc(8.5in - 15em); */
    /* letter-spacing: 0.05em; */
    /* line-height: 1.8; */
    /* padding: 5em 10em 15em 5em; */
  }
  .caps {
    color: rgba(0,0,0,0.7);
    float:left;
    font-size: 7em;
    line-height: 60px;
    padding-right: 12px;
    position:relative;
    top:8px;
  }
.ourBtn > button{
    border: 3px solid rgb(174, 4, 4)!important;
    border-radius: 281px 37px 370px / 15px 225px 15px 181px!important;
    /* font-family: 'Samarkan', sans-serif!important; */
    color:rgb(172, 24, 24)!important;
    /* font-size: larger!important; */
    /* font-weight: 800!important; */
    background-image: linear-gradient(to right, rgba(255,210,0,0.4), rgba(200, 160, 0, 0.1) 11%, rgba(0,0,0,0) 35%, rgba(200, 160, 0, 0.1) 65%)!important;
    box-shadow: inset 0 0 75px rgba(255,210,0,0.3), inset 0 0 20px rgba(255,210,0,0.4), inset 0 0 30px rgba(220,120,0,0.8)!important;

}
.ourBtn > button:hover{
    border: 3px solid rgb(174, 4, 4)!important;
    border-radius: 281px 37px 370px / 15px 225px 15px 181px!important;
    /* font-family: 'Samarkan', sans-serif!important; */
    color:rgb(172, 24, 24)!important;
    /* font-size: larger!important; */
    /* font-weight: 800!important; */
    background-image: linear-gradient(to right, rgba(255,210,0,0.4), rgba(200, 160, 0, 0.1) 11%, rgba(0,0,0,0) 35%, rgba(200, 160, 0, 0.1) 65%)!important;
    box-shadow: inset 0 0 75px rgba(10, 10, 10, 0.3), inset 0 0 20px rgba(15, 15, 15, 0.4) inset 0 0 30px rgba(12, 12, 12, 0.8)!important;
}
.headFindJob {
    color: #f7a000
}

.RojgharBtn {
    background: #bad2cc
}

.buttonOK {
    color: #bad2cc
}


.formBg{
    background-color: black !important;
    color:white !important;
    opacity: 0.7 !important;
}
.RGBG{
    background-image: url(/static/media/rgbg5.6a7a9e72.png);
    background-repeat: no-repeat;
    /* background-size: contain; */
     background-color: "#16181b";

    background-attachment: fixed;
    background-position: center;

}
.loaderclass {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 5555;
    width: 100% !important;
    height: 100% !important;
    overflow: visible;
    background: url(/static/media/loader1.a7fc8f5b.svg) no-repeat center center;

}
.headerProfile {
    color: #f3ff3a
}

.dataProfile {
    color: #96f5c5 !important
}
.app__container{display:flex;margin-top:10vh}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.header{display:flex;justify-content:space-between;align-items:center;padding:1rem 3rem;background-color:#16181b;position:fixed;top:0;height:10vh;width:100%;z-index:999}.header__menu{display:none}.header__logo{width:170px;height:170px;object-fit:contain;display:block}.header form{flex:0.6 1;display:flex;padding:0.1rem;margin:0 1rem;border-radius:3px;border:1.2px solid;background-color:#121417}.header form input{width:100%;border:none;font-weight:500;background:transparent;padding:0.3rem;color:#b1bdb4}.header form input:focus{outline:none}.header form button{padding:0 1.25rem;color:#b1bdb4;background:transparent;border:none}.header form button:focus{border:none}.header__icons{flex:0.15 1;display:flex;justify-content:space-around;align-items:center}.header__icons img{border-radius:50%;width:40px;object-fit:contain;margin-left:5px}@media (max-width: 520px){.header{padding:1rem}.header form{flex:1 1}.header__menu{display:block}.header__icons>*:not(img){display:none}}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.sidebar{background:#121417;display:flex;flex-direction:column;width:250px;height:90vh;padding-top:2rem;transition:-webkit-transform 0.2s ease-in;transition:transform 0.2s ease-in;transition:transform 0.2s ease-in, -webkit-transform 0.2s ease-in;position:-webkit-sticky;position:sticky;top:10vh}.sidebar li{display:flex;align-items:center;padding:0.6rem 1.5rem;margin:0.2rem 0;cursor:pointer}.sidebar li span{margin-left:1rem;font-size:14px;font-weight:500;letter-spacing:0.4px}.sidebar li:hover{background-color:#4c4c4c}.sidebar hr{background-color:#4c4c4c}.sidebar.open{-webkit-transform:translateX(0);transform:translateX(0)}@media (max-width: 1224px){.sidebar{width:90px}.sidebar li{justify-content:center}.sidebar span{display:none}}@media (max-width: 520px){.sidebar{-webkit-transform:translateX(-100%);transform:translateX(-100%);position:fixed;z-index:999}}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.categoriesBar{padding:0.5rem 0;font-size:0.8rem;display:flex;overflow-x:scroll;scrollbar-width:none}.categoriesBar::-webkit-scrollbar{width:0px}.categoriesBar span{margin-right:1rem;padding:0.5rem;white-space:nowrap;border:1.5px solid #b1bdb4;border-radius:999px}.categoriesBar span:hover{background-color:#374a59}.categoriesBar span.active{color:#fff;background-color:#606060;border-color:#4c4c4c}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.video{margin-bottom:1rem;padding:0.7rem;font-weight:500;font-size:0.9rem;cursor:pointer}.video__top{margin-bottom:0.5rem;position:relative}.video__top .lazy-load-image-background{width:100%}.video__top img{width:100%}.video__top__duration{position:absolute;bottom:0.3rem;right:0.3rem;padding:0.2rem;background:#080808ec;border-radius:3px}.video__title{margin-bottom:0.1rem;color:#fff;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}.video__details{display:flex;align-items:center}.video__channel{display:flex;align-items:center;margin:0.5rem 0}.video__channel img{width:36px;height:36px;border-radius:50%;margin-right:0.5rem;cursor:pointer}.video__channel p{margin-bottom:0}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.login{height:100vh;display:grid;place-items:center}.login__container{background-color:#121417;padding:2rem;margin:0 1rem;border-radius:10px;display:flex;flex-direction:column;align-items:center}.login__container button{padding:0.5rem;border:none;border-radius:5px;margin-bottom:1rem}.login__container button:focus{outline:none}.login img{width:300px;height:300px;object-fit:contain}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.shakhaCard{width:"18rem";background:"#363636";background:#363636}h5{text-align:center}

.watchScreen__player{height:60vh;background-color:#353946;width:100%;margin-bottom:2rem}

.comments__form img{width:50px;height:50px;object-fit:contain}.comments__form input{background:transparent;border:none;border-bottom:2px solid #353946;color:#fff}.comments__form input:focus{outline:none}.comments__form button{background-color:#353946;color:#fff;letter-spacing:0.5px}.comments__form button:focus{border:none;outline:none}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.videoHorizontal{border-bottom:0.3px solid #4c4c4c;cursor:pointer}.videoHorizontal__left{position:relative;text-align:center}.videoHorizontal__thumbnail{width:100%}.videoHorizontal__thumbnail-wrapper{width:100%}.videoHorizontal__duration{position:absolute;bottom:0.3rem;right:0.3rem;padding:0.2rem;background:#080808ec;border-radius:3px}.videoHorizontal__details{font-size:0.9rem}.videoHorizontal__title{font-size:1rem;color:#fff;letter-spacing:0.3px;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}.videoHorizontal__channel img{width:36px;height:36px;border-radius:50%;margin-right:0.5rem;cursor:pointer}.videoHorizontal__channel p{font-size:0.9rem;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}@media (max-width: 520px){.videoHorizontal{font-size:0.8rem}.videoHorizontal__details{overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}.videoHorizontal__channel{margin:0.1rem 0}.videoHorizontal__channel img{display:none}.videoHorizontal__channel p{font-size:0.9rem}.videoHorizontal__title{font-size:1rem;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.videoMetaData__top span{cursor:pointer}.videoMetaData__channel{border-top:0.2px solid #4c4c4c;border-bottom:0.2px solid #4c4c4c}.videoMetaData__channel img{width:50px;height:50px}.videoMetaData__channel button{background-color:red;color:#fff;border-radius:0;text-transform:uppercase;letter-spacing:0.5px}.videoMetaData__channel button:hover{color:#fff}.videoMetaData__channel button:focus{border:none;outline:none}.videoMetaData__description{font-size:0.9rem;white-space:pre-line;border-bottom:0.2px solid #4c4c4c}.videoMetaData__description .showMoreText{text-decoration:none;display:block;margin:1rem 0;color:#fff;font-weight:500}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.login{height:100vh;display:grid;place-items:center}.login__container{background-color:#121417;padding:2rem;margin:0 1rem;border-radius:10px;display:flex;flex-direction:column;align-items:center}.login__container button{padding:0.5rem;border:none;border-radius:5px;margin-bottom:1rem}.login__container button:focus{outline:none}.login img{width:300px;height:300px;object-fit:contain}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.video{margin-bottom:1rem;padding:0.7rem;font-weight:500;font-size:0.9rem;cursor:pointer}.video__top{margin-bottom:0.5rem;position:relative}.video__top .lazy-load-image-background{width:100%}.video__top img{width:100%}.video__top__duration{position:absolute;bottom:0.3rem;right:0.3rem;padding:0.2rem;background:#080808ec;border-radius:3px}.video__title{margin-bottom:0.1rem;color:#fff;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}.video__details{display:flex;align-items:center}.video__channel{display:flex;align-items:center;margin:0.5rem 0}.video__channel img{width:36px;height:36px;border-radius:50%;margin-right:0.5rem;cursor:pointer}.video__channel p{margin-bottom:0}

body{
height:auto !important;
}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.login{height:100vh;display:grid;place-items:center}.login__container{background-color:#121417;padding:2rem;margin:0 1rem;border-radius:10px;display:flex;flex-direction:column;align-items:center}.login__container button{padding:0.5rem;border:none;border-radius:5px;margin-bottom:1rem}.login__container button:focus{outline:none}.login img{width:300px;height:300px;object-fit:contain}

.watchScreen__player{height:60vh;background-color:#353946;width:100%;margin-bottom:2rem}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.video{margin-bottom:1rem;padding:0.7rem;font-weight:500;font-size:0.9rem;cursor:pointer}.video__top{margin-bottom:0.5rem;position:relative}.video__top .lazy-load-image-background{width:100%}.video__top img{width:100%}.video__top__duration{position:absolute;bottom:0.3rem;right:0.3rem;padding:0.2rem;background:#080808ec;border-radius:3px}.video__title{margin-bottom:0.1rem;color:#fff;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}.video__details{display:flex;align-items:center}.video__channel{display:flex;align-items:center;margin:0.5rem 0}.video__channel img{width:36px;height:36px;border-radius:50%;margin-right:0.5rem;cursor:pointer}.video__channel p{margin-bottom:0}

.watchScreen__player{height:60vh;background-color:#353946;width:100%;margin-bottom:2rem}

.app__container{display:flex;margin-top:10vh}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.header{display:flex;justify-content:space-between;align-items:center;padding:1rem 3rem;background-color:#16181b;position:fixed;top:0;height:10vh;width:100%;z-index:999}.header__menu{display:none}.header__logo{width:170px;height:170px;object-fit:contain;display:block}.header form{flex:0.6 1;display:flex;padding:0.1rem;margin:0 1rem;border-radius:3px;border:1.2px solid;background-color:#121417}.header form input{width:100%;border:none;font-weight:500;background:transparent;padding:0.3rem;color:#b1bdb4}.header form input:focus{outline:none}.header form button{padding:0 1.25rem;color:#b1bdb4;background:transparent;border:none}.header form button:focus{border:none}.header__icons{flex:0.15 1;display:flex;justify-content:space-around;align-items:center}.header__icons img{border-radius:50%;width:40px;object-fit:contain;margin-left:5px}@media (max-width: 520px){.header{padding:1rem}.header form{flex:1 1}.header__menu{display:block}.header__icons>*:not(img){display:none}}

*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:"Roboto", sans-serif;letter-spacing:0.1px}.bg-chalchitram{background:#16181b}::-webkit-scrollbar{width:0px}.sidebar{background:#121417;display:flex;flex-direction:column;width:250px;height:90vh;padding-top:2rem;transition:-webkit-transform 0.2s ease-in;transition:transform 0.2s ease-in;transition:transform 0.2s ease-in, -webkit-transform 0.2s ease-in;position:-webkit-sticky;position:sticky;top:10vh}.sidebar li{display:flex;align-items:center;padding:0.6rem 1.5rem;margin:0.2rem 0;cursor:pointer}.sidebar li span{margin-left:1rem;font-size:14px;font-weight:500;letter-spacing:0.4px}.sidebar li:hover{background-color:#4c4c4c}.sidebar hr{background-color:#4c4c4c}.sidebar.open{-webkit-transform:translateX(0);transform:translateX(0)}@media (max-width: 1224px){.sidebar{width:90px}.sidebar li{justify-content:center}.sidebar span{display:none}}@media (max-width: 520px){.sidebar{-webkit-transform:translateX(-100%);transform:translateX(-100%);position:fixed;z-index:999}}

table.GeneratedTable{width:100%;background-color:#1735613d;border-collapse:collapse;border-width:2px;border-color:#6370a1;border-style:solid;color:#e7e8f1}table.GeneratedTable td,table.GeneratedTable th{border-width:2px;border-color:#6370a1;border-style:solid;padding:3px}table.GeneratedTable thead{background-color:#121417}

table.GeneratedTable{width:100%;background-color:#1735613d;border-collapse:collapse;border-width:2px;border-color:#6370a1;border-style:solid;color:#e7e8f1}table.GeneratedTable td,table.GeneratedTable th{border-width:2px;border-color:#6370a1;border-style:solid;padding:3px}table.GeneratedTable thead{background-color:#121417}

table.GeneratedTable{width:100%;background-color:#1735613d;border-collapse:collapse;border-width:2px;border-color:#6370a1;border-style:solid;color:#e7e8f1}table.GeneratedTable td,table.GeneratedTable th{border-width:2px;border-color:#6370a1;border-style:solid;padding:3px}table.GeneratedTable thead{background-color:#121417}

table.GeneratedTable{width:100%;background-color:#1735613d;border-collapse:collapse;border-width:2px;border-color:#6370a1;border-style:solid;color:#e7e8f1}table.GeneratedTable td,table.GeneratedTable th{border-width:2px;border-color:#6370a1;border-style:solid;padding:3px}table.GeneratedTable thead{background-color:#121417}

table.GeneratedTable{width:100%;background-color:#1735613d;border-collapse:collapse;border-width:2px;border-color:#6370a1;border-style:solid;color:#e7e8f1}table.GeneratedTable td,table.GeneratedTable th{border-width:2px;border-color:#6370a1;border-style:solid;padding:3px}table.GeneratedTable thead{background-color:#121417}

body{
height:auto !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

