/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.iconRojghar {
  padding: 15px;
  height: 100px;
  width: 100px;
} */





























.user_cardL {
  height: 400px;
  width: 400px;
  margin-top: auto;
  margin-bottom: auto;
  /* background: #fff; */
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%);
  /* border-radius: 5px; */
  background-color: rgb(255 255 255 /20%);
  backdrop-filter: blur(20px);
}
/* #85848c */
.login_name_wrapperL {
  height: 20% !important;
  min-height: 200px;
  margin-bottom: auto;
  margin-top: auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #FFF;
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;
}

.login_logo_containerL {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background:  #85848c;
  padding: 10px;
  text-align: center;
}

.login_logoL {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form_containerL {
  margin-top: 100px;
}

.login_btnL {
  width: 100%;

  color: white !important;
}

.login_btnL:hover {
  background: #2c44b0 !important;
}

.login_containerL {
  padding: 0 2rem;
}

.input-group-text {
  background: #233588 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_userL,
.input_passL:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.custom-checkboxL .custom-control-inputL:checked~.custom-control-labelL::before {
  background-color: #233588 !important;
}

.input-group-textL {
  height: 38px;
}

.Rg-bg {
  height: 100%;
  background-image: url("./style/image/rojgharbg.jpg");
  
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* height: 95vh; */
  /* background-color: #2b4162;
  background-image: linear-gradient(315deg, #2b4162 0%, #12100e 74%) */
}

.card-glass {
  border-radius: 5px;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 0%);
  /* border-radius: 5px; */
  background-color:rgb(83 79 79 / 53%);
  opacity: 1;

  backdrop-filter: blur(20px);

}

/* .find-job-card-text {
  color:black !important;
} */
.find-job-card-textD {
  color: white !important;
}

.MuiToolbar-gutters{
  position: sticky!important;
  top: 0 !important;
  background: white;
}