.ourBtn > button{
    border: 3px solid rgb(174, 4, 4)!important;
    border-radius: 281px 37px 370px / 15px 225px 15px 181px!important;
    /* font-family: 'Samarkan', sans-serif!important; */
    color:rgb(172, 24, 24)!important;
    /* font-size: larger!important; */
    /* font-weight: 800!important; */
    background-image: linear-gradient(to right, rgba(255,210,0,0.4), rgba(200, 160, 0, 0.1) 11%, rgba(0,0,0,0) 35%, rgba(200, 160, 0, 0.1) 65%)!important;
    box-shadow: inset 0 0 75px rgba(255,210,0,0.3), inset 0 0 20px rgba(255,210,0,0.4), inset 0 0 30px rgba(220,120,0,0.8)!important;

}
.ourBtn > button:hover{
    border: 3px solid rgb(174, 4, 4)!important;
    border-radius: 281px 37px 370px / 15px 225px 15px 181px!important;
    /* font-family: 'Samarkan', sans-serif!important; */
    color:rgb(172, 24, 24)!important;
    /* font-size: larger!important; */
    /* font-weight: 800!important; */
    background-image: linear-gradient(to right, rgba(255,210,0,0.4), rgba(200, 160, 0, 0.1) 11%, rgba(0,0,0,0) 35%, rgba(200, 160, 0, 0.1) 65%)!important;
    box-shadow: inset 0 0 75px rgba(10, 10, 10, 0.3), inset 0 0 20px rgba(15, 15, 15, 0.4) inset 0 0 30px rgba(12, 12, 12, 0.8)!important;
}