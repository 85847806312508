.headFindJob {
    color: #f7a000
}

.RojgharBtn {
    background: #bad2cc
}

.buttonOK {
    color: #bad2cc
}


.formBg{
    background-color: black !important;
    color:white !important;
    opacity: 0.7 !important;
}
.RGBG{
    background-image: url("./rgbg5.png");
    background-repeat: no-repeat;
    /* background-size: contain; */
     background-color: "#16181b";

    background-attachment: fixed;
    background-position: center;

}