@import '../../_base.scss';

.login {
   height: 100vh;
   display: grid;

   place-items: center;

   &__container {
      background-color: $black-secondary;
      padding: 2rem;
      margin: 0 1rem;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
         padding: 0.5rem;
         border: none;
         border-radius: 5px;
         margin-bottom: 1rem;
         &:focus {
            outline: none;
         }
      }
   }

   img {
      width: 300px;
      height: 300px;
      object-fit: contain;
   }
}