@import "../../_base.scss";

.shakhaCard {
  width: "18rem";
  background: "#363636";

  background: rgb(54, 54, 54);
}
h5 {
  text-align: center;
}
