table.GeneratedTable {
    width: 100%;
    background-color: #1735613d;
    border-collapse: collapse;
    border-width: 2px;
    border-color: #6370a1;
    border-style: solid;
    color: #e7e8f1;
  }

  table.GeneratedTable td, table.GeneratedTable th {
    border-width: 2px;
    border-color: #6370a1;
    border-style: solid;
    padding: 3px;
  }

  table.GeneratedTable thead {
    background-color: #121417;
  }