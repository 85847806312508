.loaderclass {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5555;
    width: 100% !important;
    height: 100% !important;
    overflow: visible;
    background: url('./loader1.svg') no-repeat center center;

}